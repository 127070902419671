
import InputBase from '@mui/material/InputBase';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';


// BootstrapInput------------------------------------------------------------------------------------------------------
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 30,
        position: 'relative',
        background: 'rgba(255, 255, 255, 0.15)',
        border: '1px #CBCBCB solid',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            // 選択した時
            borderRadius: 30,
            borderColor: '#f1f1f1',
            boxShadow: '#f1f1f1',
        },
    },
}));
// BootstrapInput------------------------------------------------------------------------------------------------------



// Main------------------------------------------------------------------------------------------------------
export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    background: 'linear-gradient(360deg, #17232D 0%, #0E1B25 100%)',
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: "0.5s",
    // transition: theme.transitions.create('margin', {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    // }),
    marginTop: 60,
    overflowY: 'auto',
}));
// Main------------------------------------------------------------------------------------------------------


// Drawer------------------------------------------------------------------------------------------------------
export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        zindex: 10,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const drawerWidth = 345;
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    // transition: theme.transitions.create('width', {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    // }),
    transition: "0.5s",
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    background: 'linear-gradient(to right bottom, #40475d, #263238)',
    // transition: theme.transitions.create('width', {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    // }),
    transition: "0.5s",
    overflowX: 'hidden',
    width: "0px",
    // width: `calc(${theme.spacing(7)} + 1px)`,
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(8)} + 1px)`,
    // },
});
// Drawer------------------------------------------------------------------------------------------------------