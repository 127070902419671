import * as React from 'react';

import { FC, useEffect, useRef } from "react";
import L, { Icon } from "leaflet";
import { Marker, MarkerProps } from "react-leaflet";

// import iconShip from './icon-ship.png';
// import iconShipUnselected from './icon-ship-unselected.png';
import iconShip from '../../assets/images/icon-ship.png';
import iconShipUnselected from '../../assets/images/icon-ship-unselected.png';

import iconUrlBeaufort0 from '../../assets/images/windSpeed-0.png';
import iconUrlBeaufort1 from '../../assets/images/windSpeed-1.png';
import iconUrlBeaufort2 from '../../assets/images/windSpeed-2.png';
import iconUrlBeaufort3 from '../../assets/images/windSpeed-3.png';
import iconUrlBeaufort4 from '../../assets/images/windSpeed-4.png';
import iconUrlBeaufort5 from '../../assets/images/windSpeed-5.png';
import iconUrlBeaufort6 from '../../assets/images/windSpeed-6.png';
import iconUrlBeaufort7 from '../../assets/images/windSpeed-7.png';
import iconUrlBeaufort8 from '../../assets/images/windSpeed-8.png';
import iconUrlBeaufort9 from '../../assets/images/windSpeed-9.png';
import iconUrlBeaufort10 from '../../assets/images/windSpeed-10.png';
import iconUrlBeaufort11 from '../../assets/images/windSpeed-11.png';
import iconUrlBeaufort12 from '../../assets/images/windSpeed-12.png';

type RotableShipMarkerProps = MarkerProps & {
    timestamp: any;
    plot: number;
    index: any;
    rotationAngle: number;
    onClickMap: any;
};

type RotableWindMarkerProps = MarkerProps & {
    timestamp: any;
    plot: number;
    index: any;
    rotationAngle: number;
    onClickMap: any;
    beaufortNumber: number;
};

const merkerIcon1 = new Icon({
    iconUrl: iconShip,
    iconSize: [21, 21], //マーカーのサイズ
    iconAnchor: [10.5, 10.5], //中心位置(左上[0,0])
    className: "marker"
});
const merkerIcon2 = new Icon({
    iconUrl: iconShipUnselected,
    iconSize: [7, 21], //マーカーのサイズ
    iconAnchor: [3.5, 10.5], //中心位置(左上[0,0])
    className: "marker"
});

const iconNum = (num) =>{
    let iconUrl;
    if(num == 1){
        iconUrl = iconUrlBeaufort1;
    }else if(num == 2){
        iconUrl = iconUrlBeaufort2;
    }else if(num == 3){
        iconUrl = iconUrlBeaufort3;
    }else if(num == 4){
        iconUrl = iconUrlBeaufort4;
    }else if(num == 5){
        iconUrl = iconUrlBeaufort5;
    }else if(num == 6){
        iconUrl = iconUrlBeaufort6;
    }else if(num == 7){
        iconUrl = iconUrlBeaufort7;
    }else if(num == 8){
        iconUrl = iconUrlBeaufort8;
    }else if(num == 9){
        iconUrl = iconUrlBeaufort9;
    }else if(num == 10){
        iconUrl = iconUrlBeaufort10;
    }else if(num == 11){
        iconUrl = iconUrlBeaufort11;
    }else if(num == 12){
        iconUrl = iconUrlBeaufort12;
    }else{
        iconUrl = iconUrlBeaufort0;
    }
    return iconUrl
}

const beaufortIcon =(num) => new Icon({
    iconUrl: iconNum(num),
    // iconUrl: iconUrlBeaufort0,
    iconSize: [80, 80], //マーカーのサイズ
    iconAnchor: [40, 40], //中心位置(左上[0,0])
    className: "marker"
});

export const CustomMarker: FC<RotableShipMarkerProps> = (props) => {
    const { timestamp, plot, index, rotationAngle, onClickMap } = props;
    const markerRef = useRef<any>();

    useEffect(() => {
        let ignore = false;

        const marker = markerRef.current;

        if (marker) {
            const proto_setPos = marker._setPos;
            marker._setPos = (pos: any) => {
                proto_setPos.call(marker, pos);
                if (!ignore) {
                    if (rotationAngle) {
                        marker._icon.style[L.DomUtil.TRANSFORM + "Origin"] = 'center';
                        marker._icon.style[L.DomUtil.TRANSFORM] += "rotate(" + rotationAngle + "deg)";
                    }
                }
            };
            marker.update();
        }
        return () => {
            ignore = true;
        };
    }, [rotationAngle]);



    return (
        <>
            <Marker key={rotationAngle} ref={markerRef} {...props} icon={plot === index ? merkerIcon1 : merkerIcon2} zIndexOffset={plot === index ? 99 : 0} eventHandlers={{ click: () => onClickMap(plot, timestamp) }}>
                {props.children}
            </Marker>
        </>
    );
};

export const CustomBeaufortMarker: FC<RotableWindMarkerProps> = (props) => {
    const { timestamp, plot, index, rotationAngle, onClickMap, beaufortNumber } = props;
    const markerRef = useRef<any>();

    useEffect(() => {
        let ignore = false;

        const marker = markerRef.current;

        if (marker) {
            const proto_setPos = marker._setPos;
            marker._setPos = (pos: any) => {
                proto_setPos.call(marker, pos);
                if (!ignore) {
                    if (rotationAngle) {
                        marker._icon.style[L.DomUtil.TRANSFORM + "Origin"] = 'center';
                        marker._icon.style[L.DomUtil.TRANSFORM] += "rotate(" + rotationAngle + "deg)";
                    }
                }
            };
            marker.update();
        }
        return () => {
            ignore = true;
        };
    }, [rotationAngle]);



    return (
        <>
            <Marker key={rotationAngle} ref={markerRef} {...props} icon={beaufortIcon(beaufortNumber)} zIndexOffset={plot === index ? 99 : 0} eventHandlers={{ click: () => onClickMap(plot, timestamp) }}>
                {props.children}
            </Marker>
        </>
    );
};
